/**
 * @generated SignedSource<<fe4181e01ec2b992971885529c33c45b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Role = "ROLE_ADMIN_ACCESS" | "ROLE_ATTENTIVE_ACCOUNT" | "ROLE_CLIENT_ACCOUNT" | "ROLE_CLIENT_ADMIN_ACCOUNT" | "ROLE_CLIENT_LIMITED_ACCOUNT" | "ROLE_CLIENT_MEMBER_ACCOUNT" | "ROLE_CLIENT_STRATEGY" | "ROLE_CONCIERGE" | "ROLE_CONCIERGE_OPERATIONS" | "ROLE_CS_TAM" | "ROLE_E2E_TEST_USER" | "ROLE_ENGINEERING" | "ROLE_ENGINEERING_IDENTITY" | "ROLE_ENGINEERING_SALES" | "ROLE_ENGINEERING_SOLUTIONS" | "ROLE_IT_SECURITY" | "ROLE_MESSAGING_OPS" | "ROLE_PRODUCT_MANAGEMENT" | "ROLE_READONLY_CONCIERGE" | "ROLE_SUBSCRIBER_AUDITOR" | "ROLE_SUPER_USER" | "ROLE_UNKNOWN" | "%future added value";
export type RelayConventionsPageReferenceMfeQuery$variables = {
  companyId: string;
};
export type RelayConventionsPageReferenceMfeQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AQueryReferenceMfeFragment_query">;
};
export type RelayConventionsPageReferenceMfeQuery$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly domain: string | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
  readonly viewer: {
    readonly account: {
      readonly email: string;
      readonly firstName: string;
      readonly id: string;
    } | null;
    readonly roles: {
      readonly roles: ReadonlyArray<Role>;
    } | null;
  } | null;
};
export type RelayConventionsPageReferenceMfeQuery = {
  rawResponse: RelayConventionsPageReferenceMfeQuery$rawResponse;
  response: RelayConventionsPageReferenceMfeQuery$data;
  variables: RelayConventionsPageReferenceMfeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RelayConventionsPageReferenceMfeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AQueryReferenceMfeFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RelayConventionsPageReferenceMfeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "companyId",
                "variableName": "companyId"
              }
            ],
            "concreteType": "GetRolesResponse",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "733c2614f2bfd6c7c553918b941c5671",
    "id": null,
    "metadata": {},
    "name": "RelayConventionsPageReferenceMfeQuery",
    "operationKind": "query",
    "text": "query RelayConventionsPageReferenceMfeQuery(\n  $companyId: ID!\n) {\n  ...AQueryReferenceMfeFragment_query\n}\n\nfragment AQueryReferenceMfeFragment_query on Query {\n  ...BRolesRelayConventionsReferenceMfeFragment_query\n}\n\nfragment BRolesRelayConventionsReferenceMfeFragment_query on Query {\n  viewer {\n    roles(companyId: $companyId) {\n      roles\n    }\n  }\n  ...CPassThroughRelayConventionsReferenceMfeFragment_query\n}\n\nfragment CPassThroughRelayConventionsReferenceMfeFragment_query on Query {\n  ...DBranchWithTwoChildrenRelayConventionsReferenceMfeFragment_query\n}\n\nfragment DBranchWithTwoChildrenRelayConventionsReferenceMfeFragment_query on Query {\n  viewer {\n    account {\n      email\n      ...FAccountFirstNameRelayConventionsReferenceMfeFragment_account\n      id\n    }\n  }\n  company: node(id: $companyId) {\n    __typename\n    id\n    ... on Company {\n      ...ECompanyDomainLeafRelayConventionsReferenceMfeFragment_company\n    }\n  }\n}\n\nfragment ECompanyDomainLeafRelayConventionsReferenceMfeFragment_company on Company {\n  domain\n}\n\nfragment FAccountFirstNameRelayConventionsReferenceMfeFragment_account on Account {\n  firstName\n}\n"
  }
};
})();

(node as any).hash = "9e738f67b757225bc43326f41983511f";

export default node;
